@import "variables";

@function color($name) {
  $color: map-get($colors, $name);
  @return $color;
}

$primary: color("secondary");

$success: color("success");
$danger: color("danger");
$warning: color("primaryLight");
$info: color("secondaryLighter");

$headings-color: color("neutral");

$orange: color("primaryDark");

$table-hover-bg: color("primaryLighter");

$nav-pills-link-active-bg: color("primary");
$nav-link-color: color("black");
$nav-link-hover-color: color("primaryDark");

$code-color: color("black");

// this is needed when using the autocomplete google api in modals
// modal z-index is 1055 while this google container has 1000 by default
// TODO: change z-index of DS modal
.pac-container {
  z-index: 1056;
}
