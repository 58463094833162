html {
  height: 100%;
  min-height: 100%;
}

body {
  height: 100%;
  min-height: 100%;
}

#root {
  min-height: 100%;
  padding-bottom: 80px;
  position: relative;
}

footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.thead {
  color: white;
  background-color: #ffa117;
}

@media all and (min-width: 768px) {
  .btn-block.app-btn-block-responsive {
    width: auto;
    display: inline;
  }
  .btn-block.app-btn-block-responsive + .btn-block.app-btn-block-responsive {
    margin-top: 0;
  }
}
