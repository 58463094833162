@import "_bootstrap5_variables";

// bs4 didn't have the XXL breakpoint, so we resize it to match XL
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1141px,
);

@import "../../node_modules/@mobiltracker/bootstrap/node_modules/bootstrap/scss/bootstrap";

// Override design system header styles
h1,
h2,
h3,
h4,
h5,
h6 {
  border-bottom-width: 0px !important;
  border-bottom-style: none !important;
}

// Breadcrumbs in bs5 look very different from bs4, this makes them more alike
ol.breadcrumb {
  padding: $spacer * 0.75;
  background-color: $body-secondary-bg;
  border-radius: $border-radius * 0.75;

  a:not(:hover) {
    text-decoration: none;
  }
}
